<template>
	<div class="my-resume" v-if="data.mberSeq">
		<div class="photo">
			<file-select v-if="data.gender == 'M'" type="image" v-model="profileImg" :src="'/api/mem/prf/profile?tecMberSeq=' + data.mberSeq" defaultSrc="PROFILE" @input="saveProfile" :disabled="!auth('작성자')"/>
			<file-select v-if="data.gender == 'F'" type="image" v-model="profileImg" :src="'/api/mem/prf/profile?tecMberSeq=' + data.mberSeq" defaultSrc="PROFILE_W" @input="saveProfile" :disabled="!auth('작성자')"/>
			<template v-if="auth('작성자')">이미지 미첨부시<br/>기본이미지로 저장됩니다.</template>
		</div>
		<div class="info-text" v-if="auth('작성자')">
			※ 기본사항은 ‘개인정보관리’에서 변경 하실 수 있습니다.
			<div class="personal-info_btn" @click="$router.push('MYP102M01')">
				개인정보관리&#9654;
			</div>
		</div>
		<!-- 프로필 -->
		<div class="info" ref="profile">
			<div class="Board" style="margin-bottom: 0;margin-top: 0;">
				<table class="Board_type2" style="table-layout: auto;">
					<colgroup>
						<col width="140">
						<col width="500">
						<col width="*">
					</colgroup>
					<tbody>
						<tr>
							<th>성명</th>
							<td><InputComp type="text" v-model="data.mberNm" readonly/></td>
						</tr>
						<tr>
							<th>생년월일</th>
							<td><InputComp type="date" v-model="data.birthYyyymmdd" format="yyyy년 MM월 dd일" readonly/></td>
						</tr>
						<tr>
							<th>이메일</th>
							<td><InputComp type="text" v-model="data.email" readonly/></td>
						</tr>
						<tr>
							<th>핸드폰번호</th>
							<td><InputComp type="phone" v-model="data.hpNo" readonly/></td>
						</tr>
						<tr>
							<th>성별</th>
							<td><InputComp type="gender" classNm="gender" :value="data.gender" readonly /></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		
		<!-- 이력서 파일 업로드 -->
		<template v-if="auth('작성자')">
			<div class="filebox">
				<p>이력서 파일 업로드</p>
				<file-select type="file" v-model="temp.excelFile" accept=".xls,.xlsx" @input="excelUpload"/>
				
				<img class="excel_download" src="/images/tec/prf/excel_download.png" alt="엑셀양식다운로드" @click="$.fileDownload('/files/tech/prf/HighPro_이력서양식v1.0.2.xlsx')"/>
				<div class="kosabox">
					<img src="/images/tec/prf/kosa_btn.png" alt="코사자동입력" @click="searchKOSA()"/>
				</div>
			</div>
			<div class="filebox-notice">
				<span style="font-weight : bold; color:#000000 ">※ 이력서 파일을 업로드 하시면, 아래 이력서 항목에 자동 입력되며, 화면상에서 추가 및 수정을 하실 수 있습니다.</span>
				<br/>※ 이력서 파일은 다운로드한 양식(엑셀)으로 작성하여 업로드 바랍니다. (첨부파일 크기 제한 : 10MB)
			</div>
		</template>
		
		<!-- 자기소개 -->
		<div class="resume-box" ref="seflIntro">
			<div class="tit">자기소개<br/><span>※ 경력중심의 자기소개서가 면접요청을 받으실 확률이 높습니다!</span></div>
			<InputComp type="textarea" v-model="data.selfIntroCont" placeholder="상세설명" :readonly="!auth('작성자')"/>
		</div>
		
		<!-- 전문분야 -->
		<list-box title="전문분야" type="expert" ref="expert" class="special" v-model="data.expertCdList" key="cd" :mberSeq="data.mberSeq" v-slot="slot" style="border-top: none; margin-top: 0px;">
			<div class="Board" style="margin-bottom: 0;margin-top: 0;">
				<table class="Board_type2" style="table-layout: auto;">
					<colgroup>
						<col width="*">
					</colgroup>
					<tbody>
						<tr>
							<td class="special">
								<SelectComp class="special" type="select" v-model="slot.item.filter" :defValue="slot.item.cd && slot.item.cd.substr && slot.item.cd.substr(0, 1) || ''" cdId="PRO105" title="전문업종 선택" :disabled="!auth('작성자') || state(slot.item, '관리자확정')"/><nbsp/>
								<SelectComp class="special" type="select" v-model="slot.item.cd" cdId="PRO143" :filter="slot.item.filter" title="상세 전문분야 선택" :disabled="!auth('작성자') || state(slot.item, '관리자확정')" :rules="[{rule: ruleRequired, args: slot.item}]"/>
								<div class="levelbox special">
									<img src="/images/tec/prf/cancel_btn.png" alt="취소" v-if="auth('작성자')" @click="$refs.expert.remove(slot.index)"/>
									<template v-if="auth('관리자')">
										<div class="update-time">UPDATE / <span>{{slot.item.mberModDate | date('yyyy.MM.dd HH:mm')}}</span></div>
										<img src="/images/admin/alert.png" alt="정보수정" @click="$refs.expert.comment(slot.index)">
									</template>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</list-box>
      	
		<!-- 직무분야 -->
		<list-box title="직무분야" type="duty" ref="duty" class="special" v-model="data.dutyCdList" :mberSeq="data.mberSeq" v-slot="slot" >
			<div class="Board" style="margin-bottom: 0;margin-top: 0;">
				<table class="Board_type2" style="table-layout: auto;">
					<colgroup>
						<col width="*">
					</colgroup>
					<tbody>
						<tr>
							<td class="special">
								<SelectComp class="special" type="select" v-model="slot.item.filter" :defValue="slot.item.cd && slot.item.cd.substr && slot.item.cd.substr(0, 2) || ''" cdId="PRO141" title="직무분야 선택" :disabled="!auth('작성자') || state(slot.item, '관리자확정')"/><nbsp/>
								<SelectComp class="special" type="select" v-model="slot.item.cd" cdId="PRO142" :filter="slot.item.filter" title="상세 직무분야 선택" :disabled="!auth('작성자') || state(slot.item, '관리자확정')" :rules="[{rule: ruleRequired, args: slot.item}]"/>
								<div class="levelbox special">
									<img src="/images/tec/prf/cancel_btn.png" alt="취소" v-if="auth('작성자')" @click="$refs.duty.remove(slot.index)"/>
									<template v-if="auth('관리자')">
										<div class="update-time">UPDATE / <span>{{slot.item.mberModDate | date('yyyy.MM.dd HH:mm')}}</span></div>
										<img src="/images/admin/alert.png" alt="정보수정" @click="$refs.duty.comment(slot.index)">
									</template>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</list-box>


		<!-- 직무분야 -->
		<!-- <div class="resume-box job" ref="duty">
			<div class="tit">직무분야<br/><span>※ 중복선택 가능! 수행했던 직무를 선택하세요!</span></div>
			<div class="info">
				<div class="Board" style="margin-bottom: 0;margin-top: 0;">
					<table class="Board_type2" style="table-layout: auto;">
						<colgroup>
							<col width="*">
						</colgroup>
						<tbody>
							<tr>
								<td class="tech">
									<SelectComp type="checkbox" v-model="data.dutyCdList" cdId="PRO119" :disabled="!auth('작성자')"/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div> -->

		<!-- 보유기술 -->
		<list-box title="보유기술" type="techLevel" ref="techLevel" class="tech" v-model="data.techLevelList" key="resumeTechLevelSeq" :mberSeq="data.mberSeq" v-slot="slot">
			<div class="Board" style="margin-bottom: 0;margin-top: 0;">
				<table class="Board_type2" style="table-layout: auto;">
					<colgroup>
						<col width="*">
					</colgroup>
					<tbody>
						<tr>
							<td class="tech">
								<InputComp v-model="slot.item.techNm" classNm="tech" title="기술명" placeholder="기술명(Java, C, DB등)" :readonly="!auth('작성자') || state(slot.item, '관리자확정')" :rules="[{rule: ruleRequired, args: slot.item}]"/>
								<div class="levelbox">
									<SelectComp type="radio" v-model="slot.item.techLevelCd" cdId="PRO125" separator="/" :disabled="!auth('작성자')" :rules="[{rule: ruleRequired, args: slot.item}]"/>
									<img src="/images/tec/prf/cancel_btn.png" alt="취소" v-if="auth('작성자')" @click="$refs.techLevel.remove(slot.index)"/>
									<template v-if="auth('관리자')">
										<div class="update-time">UPDATE / <span>{{slot.item.mberModDate | date('yyyy.MM.dd HH:mm')}}</span></div>
										<img src="/images/admin/alert.png" alt="정보수정" @click="$refs.techLevel.comment(slot.index)">
									</template>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</list-box>

		<!-- 학력사항 -->
		<list-box title="학력사항" type="school" ref="school" class="special" :plus="true" v-model="data.schoolList" key="schoolSeq" desc="학력을 등록해 주세요!" :mberSeq="data.mberSeq" v-slot="slot" :mark="true">
			<div class="Board" style="margin-bottom: 0;margin-top: 0;">
				<table class="Board_type2" style="table-layout: auto;">
					<colgroup>
						<col width="*">
					</colgroup>
					<tbody>
						<tr>
							<td style="padding:2px"></td>
						</tr>
						<tr>
							<th class="school">학교명</th>
							<td :class="{school:auth('관리자')}">
								<InputComp title="학교명" v-model="slot.item.schoolNm" classNm="school" :readonly="!auth('작성자') || state(slot.item, '관리자확정,KOSA')"  :rules="[{rule: ruleRequired, args: slot.item}]"/><nbsp/>
								<SelectComp title="상태" type="select" v-model="slot.item.schoolStatusCd" cdId="PRO122" class="school" @change="changeSchoolStatus(slot.item, $event)" :disabled="!auth('작성자') || state(slot.item, '졸업확정')" :rules="[{rule: ruleRequired, args: slot.item}]"/>
							</td> 
							<th class="schclass">분류</th>
							<td :class="{schclass:auth('관리자')}">
								<SelectComp title="학교분류 선택" type="select" v-model="slot.item.schoolDivCd" cdId="PRO121" tagName="div" class="schclass" :disabled="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]"/>&nbsp;
								<img class="cancel" src="/images/tec/prf/cancel_btn.png" alt="취소" v-if="auth('작성자')" @click="state(slot.item, '관리자확정,KOSA') == true ? admAlert() : $refs.school.remove(slot.index)"/>
								<img src="/images/admin/alert.png" alt="정보수정" v-if="auth('관리자')" @click="$refs.school.comment(slot.index)">
							</td>
						</tr>
						<tr>
							<th class="major">전공</th>
							<td class="major">
								<InputComp title="전공" v-model="slot.item.majorNm" classNm="major" :readonly="!auth('작성자') || state(slot.item, '관리자확정,KOSA')"/>
								<span class="year">입학년월</span>
								<SelectDate type="MONTH" v-model="slot.item.entYyyymm" title="입학년월" from="Y-50" :to="slot.item.gradYyyymm" :disabled="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]" :classY="auth('관리자')?'':'year'"/>
							</td> 
							<th class="year">졸업년월</th>
							<td class="major"><SelectDate type="MONTH" v-model="slot.item.gradYyyymm" title="졸업년월" :from="slot.item.entYyyymm" :disabled="!auth('작성자') || state(slot.item, '졸업확정') || state(slot.item, '재학중')" :rules="[{rule: ruleRequired, args: slot.item}]" :classY="auth('관리자')?'':'year'"/></td>
						</tr>
					</tbody>
				</table>
			</div>
		</list-box>

		<!-- 자격증 -->
		<list-box title="자격증" type="license" ref="license" class="special" v-model="data.licenseList" key="resumeLicenseSeq" :mberSeq="data.mberSeq" v-slot="slot" :mark="true">
			<div class="license_search">
				<AutoComplete title="자격증명" type="text" v-model="slot.item.licenseNm" :list="code.license" listText="licenseNm" :allowMore="true" @autoComplete="inputLicense(slot.item, $event)" :disabled="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]" v-slot="slot">
					{{slot.item.licenseNm}} ({{slot.item.issOrgNm}})
				</AutoComplete>
				<div class="license_agency">
					<InputComp title="발행기관명" type="text" v-model="slot.item.issOrgNm" :disabled="!auth('작성자') || state(slot.item, '관리자확정,KOSA') || slot.item.licenseCd || !slot.item.licenseNm" :rules="[{rule: ruleRequired, args: slot.item}]"/><nbsp/>
					취득년월<nbsp/>
					<SelectDate type="MONTH" v-model="slot.item.acqYyyymm" :disabled="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]"/>&nbsp;
					<img class="cancel" src="/images/tec/prf/cancel_btn.png" alt="취소" v-if="auth('작성자')" @click="state(slot.item, '관리자확정,KOSA') == true ? admAlert() : $refs.license.remove(slot.index)"/>
					<img src="/images/admin/alert.png" alt="정보수정" v-if="auth('관리자')" @click="$refs.license.comment(slot.index)">
				</div>
			</div>
		</list-box>

		<!-- 교육사항 -->
		<list-box title="교육사항" type="edu" ref="edu" class="special" v-model="data.eduList" key="eduSeq" :mberSeq="data.mberSeq" v-slot="slot" :mark="true">
			<div class="Board" style="margin-bottom: 0;margin-top: 0;">
				<table class="Board_type2" style="table-layout: auto;">
					<colgroup>
						<col width="*">
					</colgroup>
					<tbody>
						<tr>
							<td style="padding:2px"></td>
						</tr>
						<tr>
							<td class="edu">
								<InputComp title="교육명" type="text" classNm="edu" v-model="slot.item.eduNm" :readonly="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]"/><nbsp/>
								<InputComp title="교육기관명" type="text" classNm="edu" v-model="slot.item.eduOrgNm" :readonly="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]"/>
								시작년월<nbsp/>
								<SelectDate type="MONTH" v-model="slot.item.eduStartYyyymm" title="시작년월" from="Y-50" :to="slot.item.eduEndYyyymm" :disabled="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]" :classY="auth('관리자')?'':'year'"/>
								종료년월<nbsp/>
								<SelectDate type="MONTH" v-model="slot.item.eduEndYyyymm" title="종료년월" :from="slot.item.eduStartYyyymm" :disabled="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]" :classY="auth('관리자')?'':'year'"/><nbsp/>
								<img class="edu" src="/images/tec/prf/cancel_btn.png" alt="취소" v-if="auth('작성자')" @click="$refs.edu.remove(slot.index)"/>
								<img src="/images/admin/alert.png" alt="정보수정" v-if="auth('관리자')" @click="$refs.edu.comment(slot.index)">
							</td> 
						</tr>
					</tbody>
				</table>
			</div>
		</list-box>

		<!-- 증빙서류 -->
		<list-box title="증빙서류" type="attaFile" ref="attaFile" class="special" v-model="data.attaFileRelList" key="attaFileSeq" :mberSeq="data.mberSeq" v-slot="slot">
			<div class="license_search docu">
				<AutoComplete title="증빙서류구분" type="text" v-model="slot.item.resumeAttaFileDataNm" :list="code.attaFileDivCd" listText="cdNm" :allowMore="true" @autoComplete="inputAttaFileDivCd(slot.item, $event)" :readonly="!auth('작성자') || state(slot.item, '관리자확정')" :rules="[{rule: ruleRequired, args: slot.item}]"/>
				<div class="license_agency docu">
					<file-select type="file" v-model="slot.item.file" :name="slot.item.orgFileNm" @click="clickAttaFile(slot.item, $event)" :disabled="!auth('작성자') || slot.item.attaFileSeq" :rules="[{rule: ruleRequiredFile, args: slot.item}]"/>
					<div class="levelbox docu">
						<img src="/images/tec/prf/cancel_btn.png" alt="취소" v-if="auth('작성자')" @click="$refs.attaFile.remove(slot.index)"/>
						<template v-if="auth('관리자')">
							<div class="update-time">UPDATE / <span>{{slot.item.mberModDate | date('yyyy.MM.dd HH:mm')}}</span></div>
							<img src="/images/admin/alert.png" alt="정보수정" @click="$refs.attaFile.comment(slot.index)">
						</template>
					</div>
				</div>
			</div>
		</list-box>

		<!-- 수상경력 -->
		<div class="resume-box awards" ref="awards">
			<div class="tit">수상경력</div>
			<InputComp type="textarea" classNm="awards" v-model="data.awardsCareerCont" placeholder="수상 사항이 있으면, 자유롭게 입력해 주세요!" :readonly="!auth('작성자')"/>
		</div>

		<!-- 희망근무조건 -->
		<div class="resume-box req" ref="hopeWork">
			<div class="tit">희망근무조건<br/><span>※ 중복선택 가능!</span></div>
			<div class="info">
				<div class="Board" style="margin-bottom: 0;margin-top: 0;">
					<table class="Board_type2" style="table-layout: auto;">
						<colgroup>
							<col width="*">
						</colgroup>
						<tbody>
							<tr>
								<th class="emptype">사업참여형태</th>
								<td class="emptype"><SelectComp type="checkbox" v-model="data.hopeBizPatCdList" cdId="PRO112" :disabled="!auth('작성자')"/>/</td>
								<th class="emptype">고용형태</th>
								<td class="emptype"><SelectComp type="checkbox" v-model="data.hopeEmplPatCdList" cdId="PRO113" :disabled="!auth('작성자')"/></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<InputComp type="textarea" classNm="awards" v-model="data.hopeWorkCondEtcCont" placeholder="추가 희망근무 조건 입력" :readonly="!auth('작성자')"/>
		</div>

		<!-- 재직경력 -->
		<list-box title="재직경력" type="career" ref="career" class="special" v-model="data.careerList" key="careerSeq" :mberSeq="data.mberSeq" months="workMonthCnt">
			<template v-slot:desc><span class="btm">※ 재직 경력의 근무형태 선택 시 ‘IT 정규직’에 해당하는 재직경력만 기술경력으로 인정됩니다!</span></template>
			<template v-slot:top v-if="auth('관리자')">
				<div class="top">
					<div class="docu" @click="ocr()">건강보험자격득실확인서 첨부파일 PDF, OCR 분석 <img src="/images/admin/show_btn.png" alt="보기"></div>
					<!--div class="ocr">PDF, OCR 인식 내용 보기 <img src="/images/admin/show_btn.png" alt="보기"></div-->
				</div>
			</template>
			<template v-slot="slot">
				<div class="Board" style="margin-bottom: 0;margin-top: 0;">
					<table class="Board_type2" style="table-layout: auto;">
						<colgroup>
							<col width="*">
						</colgroup>
						<tbody>
							<tr>
								<td style="padding:8px"></td>
							</tr>
							<tr>
								<th>회 사 명</th>
								<td class="career">
									<InputComp title="회사명" type="text" v-model="slot.item.companyNm" classNm="cpname" :readonly="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]"/><nbsp/>
									<SelectComp title="산업분야" type="select"  v-model="slot.item.indtypeClassCd" cdId="PRO105" class="cpname" :disabled="!auth('작성자') || state(slot.item, '관리자확정')"/><nbsp/>
									<SelectComp title="근무형태" type="select" v-model="slot.item.workPatCd" cdId="PRO123" class="cpname" :disabled="!auth('작성자') || state(slot.item, '관리자확정')" :rules="[{rule: ruleRequired, args: slot.item}]"/><nbsp/>
									<InputComp title="직급/직책" type="text" v-model="slot.item.positionNm" classNm="position" :readonly="!auth('작성자')"/><nbsp/>
									<img class="cancel" src="/images/tec/prf/cancel_btn.png" alt="취소" v-if="auth('작성자')" @click="state(slot.item, '관리자확정,KOSA') == true ? admAlert() : $refs.career.remove(slot.index)"/>
									<img src="/images/admin/alert.png" alt="정보수정" v-if="auth('관리자')" @click="$refs.career.comment(slot.index)">
								</td>
							</tr>
							<tr>
								<th class="charge">담당업무</th>
								<td class="charge">
									<InputComp title="담당업무" classNm="charge" type="text" v-model="slot.item.assigWorkNm" :readonly="!auth('작성자')"/><nbsp/>
									<p>재직기간</p><nbsp/>
									<SelectDate type="MONTH" v-model="slot.item.workStartYyyymm" title="재직시작년월" from="Y-50" :to="slot.item.workEndYyyymm" :disabled="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]" :classY="auth('관리자') ? '' : 'year'"/><nbsp/>
									<p>-</p><nbsp/>
									<SelectDate type="MONTH" v-model="slot.item.workEndYyyymm" title="재직종료년월" :from="slot.item.workStartYyyymm" :disabled="!auth('작성자') || state(slot.item, '퇴직확정') || state(slot.item, '재직중')" :rules="[{rule: ruleRequired, args: slot.item}]" :classY="auth('관리자') ? '' : 'year'"/><nbsp/>
									<SelectComp title="재직중" type="yn" v-model="slot.item.workingYn" @change="checkDoing($event, slot.item, 'workEndYyyymm', 'yyyyMM')" :disabled="!auth('작성자') || state(slot.item, '퇴직확정')"/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
		</list-box>

		<!-- 프로젝트 경력 -->
		<list-box title="프로젝트 경력" type="project" ref="project" class="special" v-model="data.projectList" key="resumeProjectSeq" :mberSeq="data.mberSeq" months="joinMonthCnt">
			<template v-slot:desc><span class="btm">※ 프로젝트 경력에 입력하신 프리랜서 경력은 기술경력으로 인정됩니다!</span></template>
			<template v-slot="slot">
				<div class="Board" style="margin-bottom: 0;margin-top: 0;">
					<table class="Board_type2" style="table-layout: auto;">
						<colgroup>
							<col width="*">
						</colgroup>
						<tbody>
							<tr><td style="padding:8px"></td></tr>
							<tr>
								<td class="project">
									<InputComp classNm="prjname" type="text" v-model="slot.item.projectNm" placeholder="프로젝트명" :readonly="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]"/><nbsp/>
									<p>시작년월</p><nbsp/>
									<SelectDate type="MONTH" v-model="slot.item.joinStartYyyymm" title="시작년월" from="Y-50" :to="slot.item.joinEndYyyymm" :disabled="!auth('작성자') || state(slot.item, '관리자확정,KOSA')" :rules="[{rule: ruleRequired, args: slot.item}]" :classY="auth('관리자') ? '' : 'year'"/><nbsp/>
									<p>종료년월</p><nbsp/>
									<SelectDate type="MONTH" v-model="slot.item.joinEndYyyymm" title="종료년월" :from="slot.item.joinStartYyyymm" :disabled="!auth('작성자') || state(slot.item, '참여종료확정') || state(slot.item, '참여중')" :rules="[{rule: ruleRequired, args: slot.item}]" :classY="auth('관리자') ? '' : 'year'"/><nbsp/>
									<SelectComp type="yn" v-model="slot.item.projectWorkingYn" title="진행중" @change="checkDoing($event, slot.item, 'joinEndYyyymm', 'yyyyMM')" :disabled="!auth('작성자') || state(slot.item, '참여종료확정')"/><nbsp/>
									<img class="cancel" src="/images/tec/prf/cancel_btn.png" alt="취소" v-if="auth('작성자')" @click="state(slot.item, '관리자확정,KOSA') == true ? admAlert() : $refs.project.remove(slot.index)"/>
									<img src="/images/admin/alert.png" alt="정보수정" v-if="auth('관리자')" @click="$refs.project.comment(slot.index)">
								</td>
							</tr>
							<tr>
								<td :class="{prjname : auth('관리자'), project:auth('작성자')}">
									<InputComp classNm="prjname" title="고객사명" type="text" v-model="slot.item.clientNm" :readonly="!auth('작성자') || state(slot.item, '관리자확정,KOSA')"/><nbsp/>
									<SelectComp class="prj" type="select" v-model="slot.item.clientIndtypeClassCd" cdId="PRO105" title="고객사 분류" :disabled="!auth('작성자') || state(slot.item, '관리자확정')"/><nbsp/>
									<InputComp classNm="prjname" title="수행사명" type="text" v-model="slot.item.workCompanyNm" :readonly="!auth('작성자') || state(slot.item, '관리자확정,KOSA')"/><nbsp/>
									<SelectComp class="prj" type="select" v-model="slot.item.workPatCd" cdId="PRO123" title="근무형태" :disabled="!auth('작성자') || state(slot.item, '관리자확정')" :rules="[{rule: ruleRequired, args: slot.item}]"/>
								</td>
							</tr>
							<tr>
								<td :class="{perform: auth('관리자'), project:auth('작성자')}">
									<InputComp classNm="task" type="text" placeholder="수행업무" v-model="slot.item.exeWorkCont" :readonly="!auth('작성자')"/><nbsp/>
									<InputComp classNm="duty" type="text" placeholder="수행직무" v-model="slot.item.exeDutyCont" :readonly="!auth('작성자')"/><nbsp/>
									<SelectComp class="prj" type="select" v-model="slot.item.joinDivCd" cdId="PRO124" title="참여구분" :disabled="!auth('작성자')"/>
								</td>
							</tr>
							<tr>
								<td :class="{devp: auth('관리자'), project:auth('작성자')}">
									<InputComp classNm="devp" type="text" placeholder="개발환경기종" v-model="slot.item.devServer" :readonly="!auth('작성자')"/><nbsp/>
									<InputComp classNm="devp" type="text" placeholder="개발환경OS" v-model="slot.item.devOs" :readonly="!auth('작성자')"/><nbsp/>
									<InputComp classNm="devp" type="text" placeholder="개발환경언어" v-model="slot.item.devLang" :readonly="!auth('작성자')"/><nbsp/>
									<InputComp classNm="devp" type="text" placeholder="개발환경DBMS" v-model="slot.item.devDbms" :readonly="!auth('작성자')"/><nbsp/>
									<InputComp classNm="devp" type="text" placeholder="개발환경TOOL" v-model="slot.item.devTool" :readonly="!auth('작성자')"/><nbsp/>
									<InputComp classNm="devp" type="text" placeholder="개발환경통신" v-model="slot.item.devComm" :readonly="!auth('작성자')"/>
								</td>
							</tr>
							<tr>
								<td :class="{explan: auth('관리자'), project:auth('작성자')}">
									<InputComp type="textarea" v-model="slot.item.projectDtlDesc" placeholder="상세설명" :readonly="!auth('작성자')"/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
		</list-box>
	</div>
</template>

<script>
import fileSelect from "@/components/FileSelect.vue";
import listBox from "@/components/highpro/ResumeListBox.vue";
export default {
	components: { fileSelect, listBox },
	props: {
		tecMberSeq: {}
	},
	data(){
		return {
			profileImg: null,
			data: {},
            code: {
                attaFileDivCd : []
            },
			temp: {},
		 }
	},
	mounted(){
        this.$.httpPost('/api/mem/prf/getLicense')
            .then(res => {
                // console.log('PRF101M01', 'getLicense', res.data);
                this.code.license = res.data.list;
            })
        this.$.httpPost('/api/mem/prf/getAttaFileDataNm')
            .then(res => {
                this.code.attaFileDivCd = res.data.list;
            });
        this.search();
	},
	methods: {
        search(){
			// console.log('PRF101M01', '이력서 조회', '/api/mem/prf/getResume');
            this.$.httpPost('/api/mem/prf/getResume', { tecMberSeq : this.tecMberSeq })
                .then(res => {
                    // console.log('PRF101M01', 'getResume', res.data);
                    
                    var data = {};
                    if(res.data.resumeTmpSaveCont && confirm('작성중인 이력서가 있습니다. 기존 정보를 불러오시겠습니까?\n작성일시: ' + this.$format.date(res.resumeTmpSaveDate, 'date').format('yyyy.MM.dd HH:mm'))){
                        data = JSON.parse(res.data.resumeTmpSaveCont);
						data.attaFileRelList = [];
                    } else {
                        data = res.data;
                        delete data.resumeTmpSaveCont;
                    }

                    this.data = data;
                    window.scrollTo(0, 0);
                    // console.log('PRF101M01', 'getResume result', this.data);
                }).catch(this.$.httpErrorCommon);
        },
		saveProfile(file){
			this.$.fileUpload('IMG_PDF_DOC', [file], '/api/mem/prf/saveProfile' )
				.then(() => {
                    // console.log('PRF101M01', 'saveProfile', res.data);
                }).catch(this.$.httpErrorCommon);
		},
        excelUpload(file){
            if(confirm('파일을 업로드하시겠습니까?')) {
                this.$.fileUpload('ALL', [file], '/api/mem/prf/uploadResumeExcel')
                    .then(res => {
                        //console.info('PRF101M01 excelUpload : v1.0.0.0', res);
                        
						if(res.data.수상경력 != null ){
							this.data.awardsCareerCont    = res.data.수상경력.trim();
						}
						if(res.data.자기소개 != null ){
							this.data.selfIntroCont       = res.data.자기소개.trim();
						}
						if(res.data.추가희망근무조건 != null ){
							this.data.hopeWorkCondEtcCont = res.data.추가희망근무조건.trim();
						}

                        //교육
                        for(var i1 in res.data.교육사항){
                            var edu = {};
                            edu.eduNm               = res.data.교육사항[i1]['교육명'].trim();
                            edu.eduOrgNm            = res.data.교육사항[i1]['교육기관명'].trim();
                            edu.eduStartYyyymm      = res.data.교육사항[i1]['시작년월'].slice(0, 6);
                            edu.eduEndYyyymm        = res.data.교육사항[i1]['종료년월'].slice(0, 6);
                            this.$refs.edu.add(edu);
                        }
                        //기술수준
                        for(var i2 in res.data.기술수준){
                            var techLevel = {};
                            techLevel.techNm        = res.data.기술수준[i2]['기술명'].trim();
                            techLevel.techLevelCd   = res.data.기술수준[i2]['기술수준'].slice(0, 1);
                            this.$refs.techLevel.add(techLevel);
                        }
                        //자격증
                        for(var i3 in res.data.자격증){
                            var license = {};
                            var sp = res.data.자격증[i3]['자격증명'].split('-');
                            license.licenseCd   = sp[0];
                            license.licenseNm   = sp[1];
                            license.issOrgNm    = sp[2];
                            license.acqYyyymm   = res.data.자격증[i3]['취득년월'].slice(0, 6);
                            this.$refs.license.add(license);
                        }
                        //재직경력
                        for(var i4 in res.data.재직경력){
                            var career = {};
                            career.companyNm         = res.data.재직경력[i4]['회사명'].trim();
                            career.positionNm        = res.data.재직경력[i4]['직위'].trim();
                            career.indtypeClassCd    = res.data.재직경력[i4]['기업구분'].slice(0, 1);
                            career.workPatCd         = res.data.재직경력[i4]['근무형태'].slice(0, 2);
                            career.assigWorkNm       = res.data.재직경력[i4]['담당업무'].trim();
                            career.workStartYyyymm   = res.data.재직경력[i4]['입사년월'].slice(0, 6);
                            career.workEndYyyymm     = res.data.재직경력[i4]['퇴사년월'].slice(0, 6);
                            if(career.workStartYyyymm && !career.workEndYyyymm){
                                //입사년월은 있는데 퇴사년월이 없을 경우 재직중 처리한다.
                                career.workingYn    = 'Y';
                                career.workEndYyyymm       = new Date().format("yyyyMM");
                            } else {
                                career.workingYn    = 'N';
                            }
                            this.$refs.career.add(career);
                        }
                        //전문분야
                        for(var i5 in res.data.전문분야){
                            var expert = {};
                            expert.filter   = res.data.전문분야[i5]['전문분야 분류'].slice(0, 1);
                            expert.cd       = res.data.전문분야[i5]['전문분야 상세'].slice(0, 4);
                            if(expert.cd.indexOf(expert.filter) != 0) {
                                //console.error('excelUpload', '전문분야', i5, expert);
                                expert.cd = '';
                            }
                            this.$refs.expert.add(expert);
                        }
                        //기술경력
                        for(var i6 in res.data.프로젝트경력){
                            var project = {};
                            project.projectNm            = res.data.프로젝트경력[i6]['프로젝트명'].trim();
                            project.joinStartYyyymm      = res.data.프로젝트경력[i6]['참여시작년월'].slice(0, 6);
                            project.joinEndYyyymm        = res.data.프로젝트경력[i6]['참여종료년월'].slice(0, 6);
                            project.clientNm             = res.data.프로젝트경력[i6]['고객사명'].trim();
                            project.clientIndtypeClassCd = res.data.프로젝트경력[i6]['고객사분류'].slice(0, 1);
                            project.workCompanyNm        = res.data.프로젝트경력[i6]['근무사명'].trim();
                            project.workPatCd            = res.data.프로젝트경력[i6]['근무형태'].slice(0, 2);
                            project.exeWorkCont          = res.data.프로젝트경력[i6]['수행업무'].trim();
                            project.exeDutyCont          = res.data.프로젝트경력[i6]['수행직무'].trim();
							project.joinDivCd            = res.data.프로젝트경력[i6]['참여구분'].slice(0, 2);
                            project.projectDtlDesc       = res.data.프로젝트경력[i6]['프로젝트\n상세설명'].trim();
                            project.devServer            = res.data.프로젝트경력[i6]['개발환경\n기종'].trim();
                            project.devOs                = res.data.프로젝트경력[i6]['개발환경\nOS'].trim();
                            project.devLang              = res.data.프로젝트경력[i6]['개발환경\n언어'].trim();
                            project.devTool              = res.data.프로젝트경력[i6]['개발환경\nTOOL'].trim();
                            project.devDbms              = res.data.프로젝트경력[i6]['개발환경\nDBMS'].trim();
                            project.devComm              = res.data.프로젝트경력[i6]['개발환경\n통신'].trim();
                            if(project.joinStartYyyymm && !project.joinEndYyyymm){
                                //참여시작년월은 있는데 참여종료년월이 없을 경우 프로젝트 진행중 처리한다.
                                project.projectWorkingYn    = 'Y';
                                project.joinEndYyyymm       = new Date().format("yyyyMM");
                            } else {
                                project.projectWorkingYn    = 'N';
                            }
                            this.$refs.project.add(project);
                        }
                        //학력
                        for(var i7 in res.data.학력사항){
                            var school = {};
                            school.schoolDivCd       = res.data.학력사항[i7]['학력구분'].slice(0, 2);
                            school.schoolNm          = res.data.학력사항[i7]['학교명'].trim();
                            school.schoolStatusCd    = res.data.학력사항[i7]['학력상태'].slice(0, 2);
                            school.majorNm           = res.data.학력사항[i7]['전공명'].trim();
                            school.entYyyymm         = res.data.학력사항[i7]['입학년월'].slice(0, 6);
                            school.gradYyyymm        = res.data.학력사항[i7]['졸업년월'].slice(0, 6);
                            this.$refs.school.add(school);
                        }
                        //직무분야
						for(var i8 in res.data.직무분야){
                            var duty = {};
                            duty.filter   = res.data.직무분야[i8]['직무분야 분류'].slice(0, 2);
                            duty.cd       = res.data.직무분야[i8]['직무분야 상세'].slice(0, 5);
                            if(duty.cd.indexOf(duty.filter) != 0) {
                                //console.error('excelUpload', '직무분야', i8, duty);
                                duty.cd = '';
                            }
                            this.$refs.duty.add(duty);
                        }

                        // var temp = [];
                        // for(var k1 in res.data.직무분야){
                        //     if(res.data.직무분야[k1] == 'true'){
                        //         temp.push(k1.slice(0, 2));
                        //     }
                        // }
                        // this.data.dutyCdList = temp.join(',');


                        //희망고용형태
						var temp = [];
                        for(var k2 in res.data.희망고용형태){
                            if(res.data.희망고용형태[k2] == 'true'){
                                temp.push(k2.slice(0, 2));
                            }
                        }
                        this.data.hopeEmplPatCdList = temp.join(',');

                        //희망사업참여형태
                        temp = [];
                        for(var k3 in res.data.희망사업참여형태){
                            if(res.data.희망사업참여형태[k3] == 'true'){
                                temp.push(k3.slice(0, 2));
                            }
                        }
                        this.data.hopeBizPatCdList = temp.join(',');
                        this.$forceUpdate();
					}).catch(err => {
					    this.temp.excelFile = null;
                    	this.$.httpErrorCommon(err);
					});
            } else {
                this.temp.excelFile = null;
            }
        },
        searchKOSA(){
            this.$.popup('/tec/prf/PRF101P01')
                .then(res => {
                    // console.log('PRF101M01', 'searchKOSA', res);
                    if(res){
                        //교육
                        for(var i1 in res.education){
                            var edu = {};
                            edu.kosaIdenti          = res.education[i1].educationCode;
                            edu.eduNm               = res.education[i1].educationName.trim();
                            edu.eduOrgNm            = res.education[i1].educationOrg.trim();
                            edu.eduStartYyyymm      = res.education[i1].educationStartDate.substring(0, 6);
                            edu.eduEndYyyymm        = res.education[i1].educationEndDate.substring(0, 6);
                            edu.kosaScrapStatusCd   = res.education[i1].approvalStatus == '3' ? '01' : '00';
                            this.$refs.edu.add(edu);
                        }
                        //학력
                        for(var i2 in res.scholar){
                            var school = {}
                            school.kosaIdenti        = res.scholar[i2].scholarNo;
                            school.schoolDivCd       = res.scholar[i2].schoolDivCd;
                            school.schoolNm          = res.scholar[i2].schoolNm.trim();
                            school.schoolStatusCd    = '00';
                            school.majorNm           = res.scholar[i2].schoolDeptName.trim();
                            school.entYyyymm         = res.scholar[i2].schoolEnterDate.substring(0, 6);
                            school.gradYyyymm        = res.scholar[i2].schoolFinishDate.substring(0, 6);
                            school.kosaScrapStatusCd = res.scholar[i2].approvalStatus == '3' ? '01' : '00';
                            this.$refs.school.add(school);
                        }
                        //기술경력
                        for(var i3 in res.skillCareer){
                            var project = {};
                            project.kosaIdenti           = res.skillCareer[i3].skillCareerNo;
                            project.projectNm            = res.skillCareer[i3].participateProjectName.trim();
                            project.joinStartYyyymm      = res.skillCareer[i3].participateStartDate.substring(0, 6);
                            project.joinEndYyyymm        = res.skillCareer[i3].participateEndDate.substring(0, 6);
                            project.projectWorkingYn     = 'N';
                            project.clientNm             = res.skillCareer[i3].orderOrgNm.trim();
                            project.workCompanyNm        = res.skillCareer[i3].companyNm.trim();
                            project.workPatCd            = res.skillCareer[i3].position == '프리랜서' ? '02' : '';
                            project.exeWorkCont          = res.skillCareer[i3].dutySkill.trim();
                            project.exeDutyCont          = res.skillCareer[i3].ncsDutyDiv1Nm;
                            project.kosaScrapStatusCd    = res.skillCareer[i3].approvalStatus == '3' ? '01' : '00';
                            this.$refs.project.add(project);
                        }
                        //자격증
                        for(var i4 in res.skillCert){
                            var license = {};
                            license.kosaIdenti        = res.skillCert[i4].skillCertNo;
                            license.licenseNm         = res.skillCert[i4].certItem.trim();
                            license.issOrgNm          = res.skillCert[i4].certPubNm.trim();
                            license.acqYyyymm         = res.skillCert[i4].passDate.substring(0, 6);
                            license.kosaScrapStatusCd = res.skillCert[i4].approvalStatus == '3' ? '01' : '00';

                            for(var j4 in this.code.license){
                                if(res.skillCert[i4].certPubNm == this.code.license[j4].licenseNm){
                                    license.licenseCd = this.code.license[j4].licenseCd;
                                    license.issOrgNm  = this.code.license[j4].issOrgNm;
                                }
                            }
                            this.$refs.license.add(license);
                        }
                        //재직경력
                        for(var i5 in res.workCareer){
                            var career = {};
                            career.kosaIdenti        = res.workCareer[i5].workCareerNo;
                            career.companyNm         = res.workCareer[i5].companyNm.trim();
                            career.positionNm        = res.workCareer[i5].position.trim();
                            career.workPatCd         = "01";
                            career.assigWorkNm       = res.workCareer[i5].ncsDutyDiv2Nm;
                            career.workStartYyyymm   = res.workCareer[i5].workStartDate.substring(0, 6);
                            career.workEndYyyymm     = res.workCareer[i5].workEndDate.substring(0, 6);
                            career.workingYn         = "N";
                            career.kosaScrapStatusCd = res.workCareer[i5].approvalStatus == '3' ? '01' : '00';
                            this.$refs.career.add(career);
                        }
                        this.$forceUpdate();
                    }
                });
        },
        ocr(){
			this.$router.push({name:'MEM910M03', query:{ tecMberSeq : this.tecMberSeq, resumeAttaFileDivCd : '03' }});
        },

        ruleRequired(v, item, msg){
            if(!v && this.auth('작성자')){
                // console.log('', 'ruleRequired', v, item, msg);
                for(var key in item){
                    if(key.indexOf('_') != 0 && item[key]){
                        if(item[key] instanceof Error){
                            // console.log('', 'ruleRequired', key, item[key].message);
                        } else {
                            return msg || '항목 입력 시 필수 입력입니다.'
                        }
                    }
                }
            }
            return true;
        },
		ruleRequiredFile(v, item, msg){
            if(!v && !item.attaFileSeq && this.auth('작성자')){
                // console.log('', 'ruleRequiredFile', v, item, msg);
                for(var key in item){
                    if(key.indexOf('_') != 0 && item[key]){
                        if(item[key] instanceof Error){
                            // console.log('', 'ruleRequired', key, item[key].message);
                        } else {
                            return msg || '항목 입력 시 필수 입력입니다.'
                        }
                    }
                }
            }
            return true;
        },

        changeSchoolStatus(item, event){
            // console.log('PRF101M01', 'changeSchoolStatus', item, event, event.target.value);
            item.statusChange = true;
            item.schoolStatusCd = event.target.value;
            if(this.state(item, '재학중')){
                item.gradYyyymm = '';
            }
        },
        inputLicense(item, value){
            // console.log('PRF101M01', 'inputLicense', item, value)
            if(value && value.issOrgNm){
                item.issOrgNm  = value.issOrgNm;
                item.licenseCd = value.licenseCd;
            } else {
                if(value){
                    alert('정해진 목록이 아닌 직접입력한 자격증은 자동 등급계산에 적용되지 않을 수 있습니다.');
                }
                item.licenseCd = '';
                item.issOrgNm = '';
            }
            //this.$forceUpdate()
        },
        inputAttaFileDivCd(item, value){
            // console.log('PRF101M01', 'inputAttaFileDivCd', item, value)
            if(value && value.cd){
                item.resumeAttaFileDivCd  = value.cd;
                item.resumeAttaFileDataNm = value.cdNm;
            } else {
                item.resumeAttaFileDivCd  = '';
                item.resumeAttaFileDataNm = '';
            }
            this.$forceUpdate()
        },
        clickAttaFile(item){
            // console.log('clickAttaFile', item, event)
            if(item.attaFileSeq){
                var param = {
                    tecMberSeq : this.data.mberSeq,
                    attaFileSeq: item.attaFileSeq
                }
                this.$.fileDownload('/api/mem/prf/downResumeFile', param)
					.catch(this.$.httpErrorCommon);
            }
        },
		checkAdminComment(){
			var count = 0;
			if(!this.data.mberSeq){
				alert("조회 결과가 없습니다.")
				return false;
			}

			for(var ref in this.$refs){
				if(this.$refs[ref].checkAdminComment){
					var check = this.$refs[ref].checkAdminComment();
					if(check === false){
						alert("작성중인 수정요청 내용이 있습니다.")
						this.$.focus(this.$refs[ref]);
						return false;
					} else {
						count += check;
					}
				}
			}
			return count;
		},
        checkDoing(event, item, key, format){
            // console.log('checkDoing', event.target.checked, item, key, format)
            if(event.target.checked){
                item[key] = new Date().format(format);
                // console.log('checkDoing', item[key])
            }
        },
        state(item, checks){
            var checkArr = checks.toUpperCase().split(',');
            if(checkArr.indexOf('관리자확정'    ) >= 0 && item.admCfmDate) return true;
            if(checkArr.indexOf('KOSA'          ) >= 0 && ['00', '01'].indexOf(item.kosaScrapStatusCd) >= 0) return true;
            if(checkArr.indexOf('KOSA확정'      ) >= 0 && item.kosaScrapStatusCd == '01') return true;
            //학력
            if(checkArr.indexOf('재학중'        ) >= 0 && ['02','03'].indexOf(item.schoolStatusCd) >= 0) return true;
            if(checkArr.indexOf('졸업확정'      ) >= 0 && this.state(item, '관리자확정,KOSA') && ['02','03'].indexOf(item.schoolStatusCd) < 0 && !item.statusChange && ['00','01', '04'].indexOf(item.cfmSchoolStatusCd) >= 0) return true;
            //재직경력
            if(checkArr.indexOf('근무중'        ) >= 0 && item.workingYn == 'Y') return true;
            if(checkArr.indexOf('퇴직확정'      ) >= 0 && this.state(item, '관리자확정,KOSA') && item.workingYn != 'Y' && !item.statusChange && item.cfmWorkingYn == 'N') return true;
            //업무경력(프로젝트)
            if(checkArr.indexOf('참여중'        ) >= 0 && item.projectWorkingYn == 'Y') return true;
            if(checkArr.indexOf('참여종료확정'  ) >= 0 && this.state(item, '관리자확정,KOSA') && item.projectWorkingYn != 'Y' && !item.statusChange && item.cfmProjectWorkingYn == 'N') return true;
            return false;
        },
        auth(checks){
            var checkArr = checks.toUpperCase().split(',');
            //console.log('PRF101M01', 'auth', checkArr, this.data, this.$store.state.userInfo, this.$store.getters);
            //console.log('PRF101M01', 'auth', 'SELF', checkArr.indexOf('SELF' ), this.data.mberSeq == this.$store.state.userInfo.mberSeq);
            if(checkArr.indexOf('작성자' ) >= 0 && this.data.mberSeq == this.$store.state.userInfo.mberSeq) return true;
            //console.log('PRF101M01', 'auth', 'ADMIN', checkArr.indexOf('ADMIN' ), this.$store.getters.isAdmin);
            if(checkArr.indexOf('관리자') >= 0 && this.$store.getters.isAdmin)  return true;
            if(checkArr.indexOf('등록')   >= 0 && this.data.regYn == 'Y')  return true;
            return false;
        },

		admAlert() {
			alert("기술등급이 확정된 상태이므로, 삭제하실 수 없습니다.");
		}
	}
}
</script>